import React from 'react';
import { RestfulProvider } from 'restful-react';
import { getConfig } from '../config';
import { useAuthToken } from './AuthTokenContext';

export const OneApiProvider = ({ children }) => {
  return (
    <ApiContext apiBase={getConfig('REACT_APP_ONE_API_BASE_URL')}>
      {children}
    </ApiContext>
  );
};

export const PollApiProvider = ({ children }) => {
  return (
    <ApiContext apiBase={getConfig('REACT_APP_POLL_API_BASE_URL')}>
      {children}
    </ApiContext>
  );
};

export const ReportGeneratorApiProvider = ({ children }) => {
  return (
    <ApiContext apiBase={getConfig('REACT_APP_REPORT_GENERATOR_API_BASE_URL')}>
      {children}
    </ApiContext>
  );
};

const ApiContext = ({ apiBase, children }) => {
  const { token } = useAuthToken();
  return (
    <RestfulProvider
      base={apiBase}
      requestOptions={{ headers: { Authorization: `Bearer ${token}` } }}
    >
      {children}
    </RestfulProvider>
  );
};

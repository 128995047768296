import { Box } from '@mui/material';
import React, { FC } from 'react';
import { TmFullBlue } from '../icons/OneIcons';

const TMLogo: FC = () => {
  return (
    <Box position="fixed" zIndex={1201} m={4} pl={2}>
      <TmFullBlue />
    </Box>
  );
};

export default TMLogo;

import React, { FC, useEffect, useState } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import './perfect-scrollbar.css';
import { Box, Collapse, useMediaQuery } from '@mui/material';
import navigationRoutes from '../../../routes/index';
import { useAuthorization } from '../../../context/AuthorizationContext';
import { useOne } from '../../../context/OneContext';
import { Drawer, Items, List, Scrollbar, SidebarSection } from './styles';
import SidebarCategory from './SidebarCategory';
import SidebarLink from './SidebarLink';
import TMLogo from '../TMLogo';

type TSidebarProps = { location: any; open: boolean } & any;
const Sidebar: FC<TSidebarProps> = ({ location, open, ...other }) => {
  const { filterRoutes, selectedProjectRole } = useAuthorization();
  const { permissions } = useAuthorization();
  const { selectedProject } = useOne();
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

  const [expandedCategory, setExpandedCategory] = useState<any>(undefined);
  const [visibleNavigationRoutes, setVisibleNavigationRoutes] = useState([]);

  const toggleActiveCategory = (category: any) => {
    if (isExpandedCategory(category)) {
      setExpandedCategory(-1);
    } else {
      setExpandedCategory(category);
    }
  };

  const isExpandedCategory = (category: any) => {
    if (expandedCategory !== undefined) {
      return category === expandedCategory;
    } else {
      return location.pathname.includes(category.path);
    }
  };

  useEffect(() => {
    if (permissions) {
      setVisibleNavigationRoutes(filterRoutes(navigationRoutes));
    } else {
      setVisibleNavigationRoutes([]);
    }

    // eslint-disable-next-line
  }, [permissions, selectedProjectRole]);

  const pathWithProjectIdent = (route: any) => {
    const path = route?.navPath || route.path[0];
    return selectedProject
      ? path.replace(':projectIdent', selectedProject.ident)
      : path;
  };

  return (
    <Drawer hideBackdrop={!isMobile} open={open} {...other}>
      {isMobile && <TMLogo />}
      <Box mb={8} />
      <Scrollbar options={{ suppressScrollX: true }}>
        <List disablePadding>
          <Items>
            {visibleNavigationRoutes.map((category: any, categoryIndex) => (
              <React.Fragment key={categoryIndex}>
                {category.header && (
                  <SidebarSection isSideBarOpen={open}>
                    {category.header}
                  </SidebarSection>
                )}

                {category.children ? (
                  <React.Fragment key={categoryIndex}>
                    <SidebarCategory
                      isOpen={isExpandedCategory(category)}
                      isCollapsable={true}
                      name={category.id}
                      icon={category.icon}
                      button={true}
                      onClick={() => toggleActiveCategory(category)}
                      isDrawerOpen={open}
                    />

                    <Collapse
                      in={isExpandedCategory(category)}
                      timeout="auto"
                      unmountOnExit
                    >
                      {category.children.map(
                        (route: any, childIndex: number) => (
                          <SidebarLink
                            key={childIndex}
                            name={route.name}
                            to={pathWithProjectIdent(route)}
                            badge={route.badge}
                          />
                        )
                      )}
                    </Collapse>
                  </React.Fragment>
                ) : (
                  <SidebarCategory
                    isCollapsable={false}
                    name={category.id}
                    to={pathWithProjectIdent(category)}
                    activeClassName="active"
                    component={NavLink}
                    icon={category.icon}
                    exact={false}
                    badge={category.badge}
                    isDrawerOpen={open}
                  />
                )}
              </React.Fragment>
            ))}
          </Items>
        </List>
      </Scrollbar>
    </Drawer>
  );
};

export default withRouter(Sidebar);

import List from '@mui/material/List';
import { Box, ListItemIcon } from '@mui/material';
import {
  CircledPlusIcon,
  ProjectIcon,
  ProjectsIcon,
} from '../../icons/OneIcons';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import React, { useEffect, useState } from 'react';
import { projectStates } from '../../../model/project_model';
import { useHistory } from 'react-router-dom';
import { useOne } from '../../../context/OneContext';
import CircularProgress from '@mui/material/CircularProgress';
import SimpleMenuPopover from '../../../pages/components/SimpleMenuPopover';
import {
  SimpleMenuListItem,
  SimpleMenuListItemButton,
} from '../../../pages/components/SimpleMenuPopover/SimpleMenuPopover.styles';
import { onePerms } from '../../../utils/oneAuthorization';
import WithPermission from '../../authorization/WithPermission';
import { useProjectsList } from '../../../services/one';

const maxVisibleProjects = 10;

const ProjectMenu = ({ open, onClose, anchorEl }) => {
  const history = useHistory();
  const { selectedProject, selectProject } = useOne();

  const {
    data: allProjects,
    isLoading: projectsLoading,
    refetch: reloadProjectsList,
  } = useProjectsList({});

  const goToAllProjects = () => {
    onClose();
    history.push('/projects');
  };

  const goToProjectAdmin = () => {
    onClose();
    history.push('/admin/projects');
  };

  const handleSelectProject = (newProject) => {
    onClose();
    selectProject(newProject);

    const { pathname } = history.location;
    const pathsWithId = [
      '/findings',
      '/questionnaires',
      '/teams',
      '/persons',
      '/repositories',
    ];
    const pathHasId = pathsWithId.some((path) => pathname.includes(path));
    const newPath = (
      pathHasId
        ? pathsWithId
            .filter((path) => pathname.includes(path))
            .map((path) => pathname.split(path)[0] + path)[0]
        : pathname
    ).replace(selectedProject?.ident, newProject?.ident);

    history.replace(newPath);
  };

  const [visibleProjects, setVisibleProjects] = useState([]);

  useEffect(() => {
    if (allProjects) {
      let filteredProjects = allProjects.filter((project) => {
        return project.state === projectStates.ACTIVE.key;
      });
      if (filteredProjects.length > maxVisibleProjects) {
        filteredProjects = filteredProjects.slice(0, maxVisibleProjects - 1);
      }
      setVisibleProjects(filteredProjects);
    } else {
      setVisibleProjects([]);
    }
  }, [allProjects]);

  useEffect(() => {
    open && reloadProjectsList();
  }, [open]);

  return (
    <SimpleMenuPopover open={open} onClose={onClose} anchorEl={anchorEl}>
      <Box width={300}>
        {selectProject && (
          <>
            <List component="nav">
              <SimpleMenuListItemButton onClick={goToAllProjects}>
                <ListItemIcon>
                  <ProjectsIcon />
                </ListItemIcon>
                <ListItemText primary="My Projects" />
              </SimpleMenuListItemButton>
            </List>
            <Divider />
          </>
        )}
        <List component="nav">
          {projectsLoading && (
            <SimpleMenuListItem>
              <ListItemIcon>
                <CircularProgress size={20} />
              </ListItemIcon>
              <ListItemText primary="loading..." />
            </SimpleMenuListItem>
          )}
          {visibleProjects.map((project) => (
            <SimpleMenuListItemButton
              selected={selectedProject && project.id === selectedProject.id}
              key={project.id}
              onClick={() => handleSelectProject(project)}
            >
              <ListItemIcon>
                <ProjectIcon />
              </ListItemIcon>
              <ListItemText primary={project.title} />
            </SimpleMenuListItemButton>
          ))}
        </List>

        <WithPermission
          validPermissions={onePerms.CREATE_PROJECTS_DUE_DILIGENCE}
        >
          <Divider />
          <List component="nav">
            <SimpleMenuListItemButton onClick={goToProjectAdmin}>
              <ListItemIcon>
                <CircledPlusIcon />
              </ListItemIcon>
              <ListItemText primary="Add new project" />
            </SimpleMenuListItemButton>
          </List>
        </WithPermission>
      </Box>
    </SimpleMenuPopover>
  );
};

export default ProjectMenu;

import styled from 'styled-components';

import { Paper as MuiPaper } from '@mui/material';

export const DRAWER_WIDTH = 260;

export const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

export const Drawer = styled.div`
  position: relative;
  z-index: 9;
  ${(props) => props.theme.breakpoints.up('md')} {
    .MuiDrawer-paper {
      width: ${DRAWER_WIDTH}px;
      flex-shrink: 0;
    }
  }
`;

export const AppContent = styled.div<{ isSidebarOpen: boolean }>`
  flex: 1;
  transition: padding-left 0.3s;
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.up('md')} {
    padding-left: ${(props) => (props.isSidebarOpen ? DRAWER_WIDTH : 48)}px;
  }
`;

export const MainContent = styled(MuiPaper)`
  flex: 1;
  background: ${(props) => props.theme.body.background};
  box-shadow: none;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { AxiosError } from 'axios';
import { getConfig } from '../../config';
import axiosClient from '../axiosClient';
import { OneApiContext } from './oneApiContext';

const baseUrl = getConfig('REACT_APP_ONE_API_BASE_URL');

export type TOneApiError = {
  error: string;
  id: string;
  path: string;
  time: string;
} & Record<string, string[]>;
export type ErrorWrapper<TError> = TError | AxiosError<TOneApiError>;

export type OneApiFetcherOptions<TBody, THeaders, TQueryParams, TPathParams> = {
  url: string;
  method: string;
  body?: TBody;
  headers?: THeaders;
  queryParams?: TQueryParams;
  pathParams?: TPathParams;
  signal?: AbortSignal;
} & OneApiContext['fetcherOptions'];

export async function oneApiFetch<
  TData,
  TError,
  TBody extends Record<string, unknown> | undefined | null,
  THeaders extends Record<string, string>,
  TQueryParams extends Record<string, any>,
  TPathParams extends Record<string, any>
>({
  url,
  method,
  body,
  headers,
  pathParams,
  queryParams,
  responseType,
  signal,
}: OneApiFetcherOptions<
  TBody,
  THeaders,
  TQueryParams,
  TPathParams
>): Promise<TData> {
  return axiosClient({
    signal,
    method,
    url: `${baseUrl}${resolveUrl(url, queryParams, pathParams)}`,
    data: body,
    responseType,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
  })
    .then((resp) => {
      return resp.data;
    })
    .catch((e: AxiosError<TOneApiError>) => {
      throw e;
    });
}

const resolveUrl = (
  url: string,
  queryParams: Record<string, string> = {},
  pathParams: Record<string, string> = {}
) => {
  let query = new URLSearchParams(queryParams).toString();
  if (query) query = `?${query}`;
  return url.replace(/\{\w*\}/g, (key) => pathParams[key.slice(1, -1)]) + query;
};

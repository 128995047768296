import React, { FC, ReactNode, useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axiosClient from '../services/axiosClient';

export const AuthTokenContext = React.createContext<{
  token: string | undefined;
  refreshToken: () => void;
  loading: boolean;
}>({ token: undefined, refreshToken: () => undefined, loading: false });
export const useAuthToken = () => useContext(AuthTokenContext);

type TAuthTokenProviderProps = { children: ReactNode };
const AuthTokenProvider: FC<TAuthTokenProviderProps> = ({ children }) => {
  const { getAccessTokenSilently, isLoading } = useAuth0();
  const [token, setToken] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const refreshToken = (callback: () => void = () => undefined) => {
    setLoading(true);
    getAccessTokenSilently()
      .then((newToken) => {
        if (token !== newToken) {
          setToken(newToken);
          axiosClient.defaults.headers.common = {
            Authorization: `Bearer ${newToken}`,
          };
        }
      })
      .finally(() => {
        setLoading(false);
        callback();
      });
  };

  useEffect(() => {
    axiosClient.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;
        if (error?.response?.status === 401 && !originalRequest?._retry) {
          originalRequest._retry = true;
          const newToken = await getAccessTokenSilently();
          setToken(newToken);
          axiosClient.defaults.headers.common = {
            Authorization: `Bearer ${newToken}`,
          };
          error.response.headers['Authorization'] = `Bearer ${newToken}`;
          return await axiosClient({
            method: originalRequest.method,
            url: originalRequest.url,
            data: originalRequest.data,
            headers: {
              ...originalRequest.headers,
              Authorization: `Bearer ${newToken}`,
            },
          });
        }
        return Promise.reject(error);
      }
    );
  }, []);

  useEffect(() => {
    !isLoading && refreshToken();
  }, [getAccessTokenSilently, isLoading]);

  return (
    <AuthTokenContext.Provider
      value={{ token, refreshToken, loading: loading || isLoading }}
    >
      {children}
    </AuthTokenContext.Provider>
  );
};

export default AuthTokenProvider;

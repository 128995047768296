import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { ContextProviders } from './context';
import VersionCheck from './components/VersionCheck';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <ContextProviders>
    <VersionCheck />
    <App />
  </ContextProviders>
);

import React from 'react';

import { ReactComponent as TmFLogoBlueSvg } from './TechMiners logo blue-white.svg';
import { ReactComponent as TmFullBlueSvg } from './TechMiners full blue-white.svg';
import { ReactComponent as ProjectsIconSvg } from './Icon - projects.svg';
import { ReactComponent as ProjectIconSvg } from './Icon - project.svg';
import { ReactComponent as ProjectWhiteIconSvg } from './Icon - project white.svg';
import { ReactComponent as ArrowDownIconSvg } from './Icon - arrow down.svg';
import { ReactComponent as ArrowDownWhiteIconSvg } from './Icon - arrow down-white.svg';
import { ReactComponent as ArrowRightIconSvg } from './Icon - arrow right.svg';
import { ReactComponent as ArrowLeftIconSvg } from './Icon - arrow left.svg';
import { ReactComponent as CircledPlusIconSvg } from './Icon - circled plus.svg';
import { ReactComponent as CircledCheckIconSvg } from './Icon - circled check.svg';
import { ReactComponent as CircledEmptyIconSvg } from './Icon - circled empty.svg';
import { ReactComponent as CloseIconSvg } from './Icon - close.svg';
import { Box } from '@mui/material';

export const TmLogoBlue = (props) => {
  return (
    <Box {...props}>
      <TmFLogoBlueSvg />
    </Box>
  );
};

export const TmFullBlue = (props) => {
  return (
    <Box {...props}>
      <TmFullBlueSvg />
    </Box>
  );
};

export const ProjectsIcon = (props) => {
  props = { height: 16, width: 16, ...props };
  return (
    <Box {...props}>
      <ProjectsIconSvg />
    </Box>
  );
};

export const ProjectIcon = (props) => {
  props = { height: 16, width: 16, ...props };
  return (
    <Box {...props}>
      <ProjectIconSvg />
    </Box>
  );
};

export const ProjectWhiteIcon = (props) => {
  props = { height: 16, width: 16, ...props };
  return (
    <Box {...props}>
      <ProjectWhiteIconSvg />
    </Box>
  );
};

export const ArrowDownIcon = (props) => {
  props = { height: 16, width: 16, ...props };
  return (
    <Box {...props}>
      <ArrowDownIconSvg />
    </Box>
  );
};

export const ArrowDownWhiteIcon = (props) => {
  props = { height: 16, width: 16, ...props };
  return (
    <Box {...props}>
      <ArrowDownWhiteIconSvg />
    </Box>
  );
};

export const ArrowRightIcon = (props) => {
  props = { height: 16, width: 16, ...props };
  return (
    <Box {...props}>
      <ArrowRightIconSvg />
    </Box>
  );
};

export const ArrowLeftIcon = (props) => {
  props = { height: 16, width: 16, ...props };
  return (
    <Box {...props}>
      <ArrowLeftIconSvg />
    </Box>
  );
};

export const CircledPlusIcon = (props) => {
  props = { height: 16, width: 16, ...props };
  return (
    <Box {...props}>
      <CircledPlusIconSvg />
    </Box>
  );
};

export const CircledCheckIcon = (props) => {
  props = { height: 16, width: 16, ...props };
  return (
    <Box {...props}>
      <CircledCheckIconSvg />
    </Box>
  );
};

export const CircledEmptyIcon = (props) => {
  props = { height: 16, width: 16, ...props };
  return (
    <Box {...props}>
      <CircledEmptyIconSvg />
    </Box>
  );
};

export const CloseIcon = (props) => {
  props = { height: 32, width: 32, ...props };
  return (
    <Box {...props}>
      <CloseIconSvg />
    </Box>
  );
};

export const formatLongString = (str: string, maxChar = 70) =>
  str.length > maxChar ? str.substring(0, maxChar) + '...' : str;

export const replaceAllNonAlphanumericCharacters = (str: string) =>
  str.replace(/[^a-zA-Z0-9_.]/gi, '_');

export const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const snakeToCamelCase = (str: string) =>
  str
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace('-', '').replace('_', '')
    );

export const capitalize = (s: string) =>
  (s && s[0].toUpperCase() + s.slice(1)) || '';

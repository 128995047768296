import { QueryClient } from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (count, err: any) => count < 3 && err?.response?.status !== 401,
    },
  },
});
export default queryClient;

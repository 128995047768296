import { Button } from '@mui/material';
import React, { FC } from 'react';
import { DRAWER_WIDTH } from '../../../layouts/dashboard/DashboardLayout.style';
import colors from '../../../theme/colors';
import Icon from '../../Icon';

type TCollapseButtonProps = { isOpen: boolean; onToggle: () => void };
const CollapseButton: FC<TCollapseButtonProps> = ({ isOpen, onToggle }) => {
  return (
    <Button
      sx={{
        position: 'fixed',
        backgroundColor: colors.black1,
        color: colors.white,
        borderRadius: isOpen ? '4px 0px 0px 4px' : '0 4px 4px 0',
        top: '90vh',
        left: isOpen ? DRAWER_WIDTH - 16 : 48,
        zIndex: 1201,
        height: 24,
        width: 16,
        minWidth: 'unset',
        padding: 0,
        ':hover': { backgroundColor: colors.black2 },
        transition: 'left 0.3s',
      }}
      onClick={onToggle}
    >
      <Icon
        category="general"
        name={isOpen ? 'arrow-small-left' : 'arrow-small-right'}
        color={colors.white}
      />
    </Button>
  );
};

export default CollapseButton;

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Helmet from 'react-helmet';

import { Button as MuiButton, Typography } from '@mui/material';
import { spacing } from '@mui/system';
import { useAuth0 } from '@auth0/auth0-react';

const Button = styled(MuiButton)(spacing);

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)};
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const Page403 = () => {
  const { isAuthenticated, logout } = useAuth0();

  return (
    <Wrapper data-testid="error-403">
      <Helmet title="403 Error" />
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        403
      </Typography>

      <Typography component="h2" variant="h5" align="center" gutterBottom>
        Permission Denied.
      </Typography>
      <Typography component="h2" variant="body1" align="center" gutterBottom>
        You do not have the necessary permissions to access this resource.
        Please contact dd@techminers.com if you think this is a
        misconfiguration.
      </Typography>

      <Button
        component={Link}
        to="/projects"
        variant="contained"
        color="primary"
        mt={2}
        mr={2}
      >
        Project List
      </Button>

      {isAuthenticated && (
        <Button variant="contained" mt={2} onClick={logout}>
          Logout
        </Button>
      )}
    </Wrapper>
  );
};

export default Page403;

import { Button } from '@mui/material';
import { CloseSharp } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useClearCache } from 'react-clear-cache';

const VersionCheck = () => {
  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleUpdate = (snackbarId: any) => {
    closeSnackbar(snackbarId);
    emptyCacheStorage();
  };

  const action = (snackbarId: any) => (
    <>
      <Button
        style={{ fontWeight: 600, color: '#fff' }}
        onClick={() => handleUpdate(snackbarId)}
      >
        Update
      </Button>
      <Button
        variant="text"
        style={{ color: '#fff' }}
        onClick={() => {
          closeSnackbar(snackbarId);
        }}
      >
        <CloseSharp fontSize="small" />
      </Button>
    </>
  );

  useEffect(() => {
    !isLatestVersion &&
      enqueueSnackbar('A newer version of ONE is available', {
        action,
        variant: 'info',
        persist: true,
      });
  }, [isLatestVersion]);

  return null;
};
export default VersionCheck;

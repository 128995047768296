import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useOne } from '../../context/OneContext';
import AppLoading from '../public/AppLoading';

const Dashboard = () => {
  const { selectedProject } = useOne();
  const history = useHistory();
  useEffect(() => {
    selectedProject &&
      history.replace(`/projects/${selectedProject.ident}/reporting`);
  }, [selectedProject]);
  if (!selectedProject) return <AppLoading silent />;
  return 'Redirecting...';
};
export default Dashboard;

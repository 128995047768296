import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { unprotectedRoutes } from './index';

import PublicLayout from '../layouts/PublicLayout';
import generateRoutes from './generateRoutes';

const PublicRoutes = () => {
  return (
    <PublicLayout>
      <Switch>{generateRoutes(unprotectedRoutes, Route)}</Switch>
    </PublicLayout>
  );
};

export default PublicRoutes;

import React, { FC, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { LinkBadge, SidebarStyleLink, SidebarStyleLinkText } from './styles';

type TSidebarLinkProps = { name: ReactNode; to: string; badge: ReactNode };
const SidebarLink: FC<TSidebarLinkProps> = ({ name, to, badge }) => {
  return (
    <SidebarStyleLink
      button
      dense
      component={NavLink}
      exact
      to={to}
      activeClassName="active"
    >
      <SidebarStyleLinkText>{name}</SidebarStyleLinkText>
      {badge ? <LinkBadge label={badge} /> : ''}
    </SidebarStyleLink>
  );
};

export default SidebarLink;

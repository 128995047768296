import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { protectedRoutes } from './index';

import DashboardLayout from '../layouts/dashboard';
import ProtectedRoute from './ProtectedRoute';
import generateRoutes from './generateRoutes';
import { useOne } from '../context/OneContext';
import { Helmet } from 'react-helmet';

const ProtectedRoutes = () => {
  const { selectedProject } = useOne();
  return (
    <DashboardLayout>
      <Helmet
        titleTemplate={`%s | ${selectedProject?.title || 'TechMiners'}`}
        defaultTitle="TechMiners ONE"
      />
      <Switch>
        {generateRoutes(protectedRoutes, ProtectedRoute)}
        <Route>
          <Redirect to={'/public/404'} />
        </Route>
      </Switch>
    </DashboardLayout>
  );
};

export default ProtectedRoutes;

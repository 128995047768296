import colors from './colors';

const techMinersVariant = {
  name: 'TechMiners',
  palette: {
    primary: {
      main: colors.blue0,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.red,
      contrastText: colors.white,
    },
    error: {
      main: colors.red,
    },
    warning: {
      main: colors.lightRed,
    },
    info: {
      main: colors.lightGreen,
    },
    success: {
      main: colors.green,
    },
    undefined: {
      main: colors.black4,
    },
    text: {
      primary: colors.black0,
      secondary: colors.blue0,
      light: colors.black2,
    },
    paper: {
      borderColor: colors.black5,
      highlightBorderColor: colors.black0,
    },
    background: {
      default: colors.white,
      paper: colors.white,
      alternating: colors.black6,
    },
    maturity: {
      background: colors.white,
      above: colors.lightGreen,
      aboveBar: colors.green,
      moreAbove: colors.green,
      below: colors.lighterRed,
      belowBar: colors.lightRed,
      moreBelow: colors.lightRed,
      actual: colors.blue0,
      neutral: colors.lightGrey,
      expectancy: colors.black0,
      noExpectancy: colors.black5,
    },
  },
  tabs: {
    background: {
      selected: colors.black0,
      unselected: colors.black6,
      outlined: colors.white,
    },
    text: {
      selected: colors.white,
      unselected: colors.black0,
      outlined: colors.black2,
    },
    border: {
      selected: colors.black0,
      unselected: colors.black6,
      outlined: colors.black2,
    },
  },
  header: {
    color: colors.white,
    background: colors.black0,
    search: {
      color: colors.grey,
    },
    indicator: {
      background: colors.blue0,
    },
  },
  sidebar: {
    color: colors.white,
    background: colors.black0,

    header: {
      color: colors.white,
      background: colors.black0,
      brand: {
        color: colors.white,
      },
    },
    footer: {
      color: colors.white,
      background: colors.black0,
      online: {
        background: colors.green,
      },
    },
    category: {
      fontWeight: 400,
      fontSize: 16,
    },
    section: {
      header: { color: colors.black3 },
      divider: { color: colors.black1 },
    },
    badge: {
      color: colors.white,
      background: colors.blue0,
    },
  },
  body: {
    background: colors.white,
  },
};

const variants = [techMinersVariant];

export default variants;

import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { IconButton, Avatar, Stack } from '@mui/material';
import { Menu, TMenuProps } from '@techminers/tm-ui';
import { getConfig } from '../../../config';
import colors from '../../../theme/colors';
import Icon from '../../Icon';
import SettingDialog from './SettingModal';
import { useHistory } from 'react-router-dom';
import { FiLogOut, FiUser } from 'react-icons/fi';

const HeaderAction = () => {
  const { logout, user } = useAuth0();
  const [isSettingDialogOpen, setIsSettingDialogOpen] = useState(false);
  const history = useHistory();

  const handleOpenSettingModal = () => setIsSettingDialogOpen(true);
  const handleCloseSettingModal = () => setIsSettingDialogOpen(false);

  const menuOptions: TMenuProps['options'] = [
    {
      content: user?.name,
      onClick: () => history.push('/profile'),
      icon: <FiUser size={20} />,
    },
    {
      content: 'Sign out',
      onClick: () =>
        logout({
          logoutParams: {
            returnTo: getConfig('REACT_APP_AUTH0_LOGOUT_URL') as string,
          },
        }),
      icon: <FiLogOut size={20} />,
    },
  ];

  return (
    <>
      <Stack direction={'row-reverse'} alignItems={'center'} p={0}>
        <Menu options={menuOptions}>
          <IconButton sx={{ color: colors.white }}>
            <Avatar alt={user?.name} src={user?.picture} />
          </IconButton>
        </Menu>
        <IconButton
          onClick={handleOpenSettingModal}
          sx={{
            color: colors.white,
            backgroundColor: colors.black1,
            height: 40,
            width: 40,
          }}
        >
          <Icon category="general" name="settings" />
        </IconButton>
      </Stack>
      <SettingDialog
        open={isSettingDialogOpen}
        onClose={handleCloseSettingModal}
      />
    </>
  );
};
export default HeaderAction;

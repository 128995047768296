export const onePerms = {
  SUPER_ADMIN: 'one:superadmin:*',
  COLLABORATE_ON_PROJECTS: 'one:collaborate-on:projects',
  ADMIN_REFERENCE_DATA: 'one:admin:reference-data',
  CREATE_PROJECTS_DUE_DILIGENCE: 'one:create:projects:DUE_DILIGENCE',
  CREATE_PROJECTS_SANITY_CHECK: 'one:create:projects:SANITY_CHECK',
  VIEW_USERS: 'one:view:users',
  POLL_AUDIT_ANSWERS: 'poll:audit:answers',
  ONE_EXPERIMENTAL_FEATURES: 'one:show:experimental-features',
  ONE_PROMPT_ENGINEER: 'one:update:ai-assistant-config',
};

export const onePermGroups = {
  CREATE_PROJECTS: [
    onePerms.CREATE_PROJECTS_DUE_DILIGENCE,
    onePerms.CREATE_PROJECTS_SANITY_CHECK,
  ],
};

export const projectRoles = {
  PROJECT_ADMIN: 'project-admin',
  PROJECT_ANALYST: 'project-analyst',
  REPORT_RECEIVER: 'report-receiver',
  QUESTIONNAIRE_RESPONDER: 'questionnaire-responder',
};

export const projectRoleGroups = {
  REPORT_EDITORS: [projectRoles.PROJECT_ADMIN, projectRoles.PROJECT_ANALYST],
  REPORT_VIEWERS: [
    projectRoles.PROJECT_ADMIN,
    projectRoles.PROJECT_ANALYST,
    projectRoles.REPORT_RECEIVER,
  ],
};

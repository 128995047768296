import React from 'react';
import styled from 'styled-components';
import packageInfo from '../../../package.json';

import {
  Box,
  List,
  ListItem as MuiListItem,
  ListItemText,
} from '@mui/material';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => `1px ${props.theme.spacing(4)}`};
  background: ${(props) => props.theme.palette.common.white};
  position: relative;
`;

const ListItem = styled(MuiListItem)`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)};
  padding-right: ${(props) => props.theme.spacing(2)};

  &,
  &:hover,
  &:active {
    color: #000;
  }
`;

function Footer() {
  return (
    <Container>
      <List>
        <ListItem
          component="a"
          href="https://www.techminers.com/"
          target="_blank"
        >
          <ListItemText
            primary={`© ${new Date().getFullYear()} - TechMiners`}
          />
        </ListItem>
      </List>
      <Box sx={{ p: '20px 16px', opacity: 0.3 }}>
        ONE v{packageInfo.version}
      </Box>
    </Container>
  );
}

export default Footer;

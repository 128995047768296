import React, { FC } from 'react';
import NavigationIcons from '../assets/icons/navigation.svg';
import GeneralIcons from '../assets/icons/general.svg';
import DocumentsIcons from '../assets/icons/documents.svg';
import TasksIcons from '../assets/icons/tasks.svg';
import StatusesIcons from '../assets/icons/statuses.svg';
import FilterIcons from '../assets/icons/filter.svg';
import ChartsIcons from '../assets/icons/charts.svg';
import FormsIcons from '../assets/icons/forms.svg';

type TIconCategories =
  | 'navigation'
  | 'general'
  | 'documents'
  | 'tasks'
  | 'statuses'
  | 'filter'
  | 'charts'
  | 'forms';
type TIconProps = {
  category: TIconCategories;
  name: string;
  fillColor?: string;
  color?: string;
  size?: string;
};

const Icons: { [key in TIconCategories]: string } = {
  navigation: NavigationIcons,
  general: GeneralIcons,
  documents: DocumentsIcons,
  tasks: TasksIcons,
  statuses: StatusesIcons,
  filter: FilterIcons,
  charts: ChartsIcons,
  forms: FormsIcons,
};

const Icon: FC<TIconProps> = ({
  category,
  name,
  fillColor = 'none',
  color = 'inherit',
  size = '16px',
}) => {
  return (
    <svg width={size} height={size} fill={fillColor} color={color}>
      <use href={Icons[category] + `#${name}`} />
    </svg>
  );
};
export default Icon;

import { useOne } from '../../../context/OneContext';
import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { ArrowDownWhiteIcon, ProjectWhiteIcon } from '../../icons/OneIcons';
import ProjectMenu from './ProjectMenu';

const ProjectSelection = () => {
  const { selectedProject } = useOne();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        onClick={handleClick}
        display="flex"
        alignItems="center"
        style={{ cursor: 'pointer' }}
        data-testid="header-projects-dropdown-button"
      >
        <ProjectWhiteIcon mr={4} />
        <Typography sx={{ color: theme.palette.primary.contrastText }}>
          {selectedProject ? selectedProject.title : 'My Projects'}
        </Typography>
        <ArrowDownWhiteIcon ml={4} />
      </Box>
      <ProjectMenu
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
      />
    </>
  );
};

export default ProjectSelection;

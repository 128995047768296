import React from 'react';
import { useAuthorization } from '../../context/AuthorizationContext';

const WithPermission = ({ validPermissions, validRoles, children }) => {
  const { userHasValidRole, userHasValidPermission } = useAuthorization();

  const checkPermission = () => {
    if (validPermissions && !userHasValidPermission(validPermissions)) {
      return false;
    }
    if (validRoles && !userHasValidRole(validRoles)) {
      return false;
    }

    // in all other cases return true
    return true;
  };

  if (checkPermission()) {
    return <>{children}</>;
  } else {
    return <></>;
  }
};

export default WithPermission;

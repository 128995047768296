import React, { FC, useEffect, useState } from 'react';
import { Button, Dialog, Stack, Tooltip, Typography } from '@mui/material';
import { Switch } from '@techminers/tm-ui';
import colors from '../../../theme/colors';
import IconButton from '../../IconButton';
import { FiInfo, FiX } from 'react-icons/fi';

type TSettingDialogProps = {
  open: boolean;
  onClose: () => void;
};

const SettingDialog: FC<TSettingDialogProps> = ({ open, onClose }) => {
  const [isCollaborationCopilotActive, setIsCollaborationCopilotActive] =
    useState<boolean>(
      localStorage.getItem('isCollaborationCopilotDisabled') !== 'true'
    );
  const [
    isCollaborationCopilotNeuralSpellcheckActive,
    setIsCollaborationCopilotNeuralSpellcheckActive,
  ] = useState<boolean>(
    localStorage.getItem('isCollaborationCopilotNeuralSpellcheckActive') ===
      'true'
  );

  // to initialize features
  useEffect(() => {
    if (localStorage.getItem('isCollaborationCopilotDisabled') === null) {
      localStorage.setItem('isCollaborationCopilotDisabled', 'true');
      setIsCollaborationCopilotActive(false);
    }
    if (
      localStorage.getItem('isCollaborationCopilotNeuralSpellcheckActive') ===
      null
    ) {
      localStorage.setItem(
        'isCollaborationCopilotNeuralSpellcheckActive',
        'true'
      );
      setIsCollaborationCopilotNeuralSpellcheckActive(true);
    }
  }, []);

  const handleToggleCopilotChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = event.target.checked;
    setIsCollaborationCopilotActive(newValue);
    localStorage.setItem('isCollaborationCopilotDisabled', String(!newValue));
  };

  const handleToggleCopilotNeuralSpellcheckChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = event.target.checked;
    setIsCollaborationCopilotNeuralSpellcheckActive(newValue);
    localStorage.setItem(
      'isCollaborationCopilotNeuralSpellcheckActive',
      String(newValue)
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { p: 6, borderRadius: 2 } }}
      maxWidth="xs"
      fullWidth
    >
      <Stack spacing={2}>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          pb={2}
        >
          <Typography variant="h2">ONE Settings</Typography>
          <IconButton onClick={onClose}>
            <FiX fontSize={20} />
          </IconButton>
        </Stack>

        <Stack
          direction={'column'}
          sx={{ bgcolor: colors.black6, p: 2, borderRadius: 2 }}
        >
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            mb={2}
          >
            <Typography>Collaborative Editor Copilot (Beta)</Typography>
            <Switch
              checked={isCollaborationCopilotActive}
              onChange={handleToggleCopilotChange}
            />
          </Stack>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{
              opacity: isCollaborationCopilotActive ? 1 : 0.5,
              borderLeft: `2px solid ${colors.black4}`,
              pl: 2,
              py: 1,
              ml: 1,
            }}
          >
            <Typography>
              Neural spellcheck
              <Tooltip
                title={
                  'Copilot will use more powerful neural (GPT-style) model to make spelling suggestions.'
                }
              >
                <Button sx={{ minWidth: 0 }}>
                  <FiInfo />
                </Button>
              </Tooltip>
            </Typography>
            <Switch
              checked={isCollaborationCopilotNeuralSpellcheckActive}
              onChange={handleToggleCopilotNeuralSpellcheckChange}
              disabled={!isCollaborationCopilotActive}
            />
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default SettingDialog;

import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { protectedRoutes, unprotectedRoutes } from '.';
import { ProtectedContextProviders } from '../context';
import ProtectedRoutes from './ProtectedRoutes';
import PublicRoutes from './PublicRoutes';

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={unprotectedRoutes.map((item) => item.path).flat()}>
          <PublicRoutes />
        </Route>
        <Route path={protectedRoutes.map((item) => item.path).flat()}>
          <ProtectedContextProviders>
            <ProtectedRoutes />
          </ProtectedContextProviders>
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;

const colors = {
  black: '#000000',
  black0: '#182631', // TechMiners black
  black1: '#263540',
  black2: '#677986',
  black3: '#81919C',
  black4: '#A3AFB9',
  black5: '#C5CED5',
  black6: '#F0F3F7',
  blue0: '#3B6AE3', // TechMiners blue
  blue1: '#7697EC',
  blue2: '#9DB5F1',
  blue3: '#C4D2F7',
  blue4: '#D8E1F9',
  blue5: '#EBF0FC',
  blue6: '#F5F8FE',
  white: '#ffffff', // TechMiners white
  grey: '#263540',
  lightGrey: '#F0F3F7',
  green: '#A9DC77',
  lightGreen: '#DBEC72',
  red: '#DB4C5D',
  lightRed: '#FF8469',
  lighterRed: '#FFDBAB',
};

export default colors;

import React from 'react';
import styled from 'styled-components';

import Helmet from 'react-helmet';

import { Typography } from '@mui/material';

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)};
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function AppLoading({ silent }) {
  if (silent) {
    return <></>;
  } else {
    return (
      <Wrapper data-testid="app-loading">
        <Helmet title="App Loading" />
        <Typography component="h1" variant="h1" align="center" gutterBottom>
          App Loading
        </Typography>
        <Typography component="h2" variant="h5" align="center" gutterBottom>
          Please wait until the app is loaded.
        </Typography>
        <Typography component="h2" variant="body1" align="center" gutterBottom>
          If you are not redirected within 10 seconds, please reload the page.
        </Typography>
      </Wrapper>
    );
  }
}

export default AppLoading;

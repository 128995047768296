import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { getConfig } from '../config';
import { hotjar } from 'react-hotjar';

const hotjarTrackingCode = getConfig('HOTJAR_TRACKING_CODE') as number;

const useHotjar = () => {
  const { user } = useAuth0();
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      hotjar.initialize(hotjarTrackingCode, 6);
      hotjar.identify(user?.sub || 'undefined', user || {});
    }
  }, [user]);
};

export const hotjarEvents = {
  createQuestionnaireDialogOpened: () =>
    process.env.NODE_ENV === 'production' &&
    hotjar.event('create_questionnaire_dialog_opened'),
  maturityRatingPopoverOpened: () =>
    process.env.NODE_ENV === 'production' &&
    hotjar.event('maturity_rating_popover_opened'),
  maturityEditModeOpened: () =>
    process.env.NODE_ENV === 'production' &&
    hotjar.event('maturity_edit_mode_opened'),
  reportSummaryTabOpened: (tab: string) =>
    process.env.NODE_ENV === 'production' &&
    hotjar.event(`report_summary_tab_${tab}_opened`),
};

export default useHotjar;

import React from 'react';
import styled from 'styled-components';

import { CssBaseline } from '@mui/material';
import { PublicLayoutGlobalStyle } from './globalStyles';

const Root = styled.div`
  max-width: 520px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

function PublicLayout({ children }) {
  return (
    <Root>
      <CssBaseline />
      <PublicLayoutGlobalStyle />
      {children}
    </Root>
  );
}

export default PublicLayout;

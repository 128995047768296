import { ButtonProps } from '@mui/material';
import { Button } from '@techminers/tm-ui';
import React, { FC } from 'react';

type TIconButtonProps = ButtonProps;
const IconButton: FC<TIconButtonProps> = ({ sx, children, ...other }) => {
  return (
    <Button
      variant="text"
      sx={{
        minWidth: 'unset',
        height: 'unset !important',
        padding: '0 !important',
        ...sx,
      }}
      {...other}
    >
      {children}
    </Button>
  );
};

export default IconButton;

import { ErrorWrapper } from './oneApiFetcher';
import { enqueueSnackbar } from 'notistack';

const isJson = (json: string) => {
  const normalizedJson = json.replaceAll("'", '"');
  try {
    JSON.parse(normalizedJson);
  } catch (error) {
    return false;
  }
  return true;
};

const defaultErrorObjectFields = ['error', 'id', 'path', 'time'];
const defaultErrorMessage =
  'Something went wrong with your request! Please try again or contact support.';

export const errorHandler = (error: ErrorWrapper<undefined>, key: any = '') => {
  const errorObject = error?.response?.data;
  let errorMessage = 'Error: ';
  try {
    if (errorObject) {
      const fieldsWithError = Object.keys(errorObject).filter(
        (key) => !defaultErrorObjectFields.includes(key)
      );
      const errorField = errorObject?.error ? 'error' : fieldsWithError[0];
      const errorValue = errorObject[errorField] as string | string[];

      if (!errorValue) throw new Error('no error data available');

      const parsedError =
        typeof errorValue === 'string' && isJson(errorValue)
          ? JSON.parse(errorValue.replaceAll("'", '"'))
          : errorValue;
      errorMessage +=
        typeof parsedError === 'string' ? parsedError : parsedError.join(', ');
    } else {
      errorMessage = defaultErrorMessage;
    }
  } catch (error) {
    errorMessage = defaultErrorMessage;
  }
  enqueueSnackbar(errorMessage, {
    variant: 'error',
    key: key ?? undefined,
    preventDuplicate: !!key,
  });
};
export default errorHandler;

import React from 'react';
import { Backdrop, Popover } from '@mui/material';

export const SimpleMenuPopover = ({
  open,
  onClose,
  anchorEl,
  children,
  alignX = 'center',
}) => {
  return (
    <>
      <Backdrop open={open} style={{ zIndex: 3000 }}></Backdrop>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: alignX,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{ marginTop: 22, zIndex: 3001 }}
      >
        {children}
      </Popover>
    </>
  );
};

import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import Page403 from '../pages/public/Page403';
import { useAuthorization } from '../context/AuthorizationContext';
import { useOne } from '../context/OneContext';
import AppLoading from '../pages/public/AppLoading';
import PublicLayout from '../layouts/PublicLayout';

const ProtectedRoute = ({
  path,
  children,
  validPerms,
  validRoles,
  ...rest
}) => {
  const { userMayAccessRoute } = useAuthorization();
  const { projectsLoading } = useOne();
  if (projectsLoading)
    return (
      <PublicLayout>
        <AppLoading />
      </PublicLayout>
    );
  if (userMayAccessRoute({ path, validPerms, validRoles }))
    return (
      <Route path={path} {...rest}>
        {children}
      </Route>
    );

  return <Page403 />;
};

ProtectedRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element]).isRequired,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
};

export default ProtectedRoute;

import styled from 'styled-components';
import ListItem from '@mui/material/ListItem';
import { ListItemButton } from '@mui/material';

export const SimpleMenuListItem = styled(ListItem)`
  padding: 16px 16px 16px 24px;
`;

export const SimpleMenuListItemButton = styled(ListItemButton)`
  padding: 16px 16px 16px 24px;
`;

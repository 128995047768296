import React, { useContext, useEffect, useState } from 'react';
import { useOne } from './OneContext';
import { onePerms, projectRoles } from '../utils/oneAuthorization';
import jwt_decode from 'jwt-decode';
import PublicLayout from '../layouts/PublicLayout';
import AppLoading from '../pages/public/AppLoading';
import { useAuthToken } from './AuthTokenContext';
import { useAuth0 } from '@auth0/auth0-react';
import { useProjectsTeamMembersList } from '../services/one';

/**
 * Checks whether a user has valid permissions.
 *
 * @param {List}    validPermissions    List of valid permissions
 * @param {List}    userPermissions     List of all permissions the user has
 *
 * @return {boolean} true if permission should be granted, false if not
 */
const hasValidPermission = (validPermissions, userPermissions) => {
  if (!validPermissions || userPermissions.includes(onePerms.SUPER_ADMIN)) {
    // TRUE if no permissions required or if user is super-admin
    return true;
  } else if (!userPermissions || !userPermissions.length) {
    // FALSE if user has no permissions
    return false;
  } else if (
    userPermissions.filter((permission) =>
      validPermissions.includes(permission)
    ).length
  ) {
    // TRUE if any userPermission matches any validPermissions (intersection)
    return true;
  } else {
    // FALSE in all other cases
    return false;
  }
};

/**
 * Checks whether a user has a valid role.
 *
 * @param {List}    validRoles       List of valid roles
 * @param {string}  userProjectRole  The user's role (in a project)
 *
 * @return {boolean} true if permission should be granted, false if not
 */
const hasValidRole = (validRoles, userProjectRole) => {
  if (!validRoles || !validRoles.length) {
    // TRUE if no roles required
    return true;
  } else if (!userProjectRole) {
    // FALSE if the user has no role at all
    return false;
  } else {
    // otherwise check whether the role is valid
    return validRoles.includes(userProjectRole);
  }
};

const isAuthorizedRoute = (route, userPermissions, userProjectRole) => {
  return (
    hasValidPermission(route.validPerms, userPermissions) &&
    hasValidRole(route.validRoles, userProjectRole)
  );
};

const getAuthorizedRoutes = (routes, userPermissions, userProjectRole) => {
  return routes
    .filter((route) =>
      isAuthorizedRoute(route, userPermissions, userProjectRole)
    )
    .map((route) => {
      if (route.children) {
        return {
          ...route,
          children: getAuthorizedRoutes(
            route.children,
            userPermissions,
            userProjectRole
          ),
        };
      } else {
        return route;
      }
    })
    .filter(
      (route) => route.component || (route.children && route.children.length)
    );
};

const filterVisibility = (selectedProject, routes) => {
  return routes.filter(() => {
    // no restrictions on visibility for now
    return true;
  });
};

export const AuthorizationContext = React.createContext();
export const useAuthorization = () => useContext(AuthorizationContext);
export const AuthorizationProvider = ({ children }) => {
  const {
    user,
    isLoading: isAuthLoading,
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0();
  const { selectedProject } = useOne();
  const { token } = useAuthToken();
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    let mounted = true;
    mounted && token && setPermissions(jwt_decode(token).permissions);
    return () => (mounted = false);
  }, [token]);

  //const [loading, setLoading] = useState(true);

  const { data: projectTeamMembers, isLoading: loading } =
    useProjectsTeamMembersList(
      { pathParams: { id: selectedProject?.id || '' } },
      { enabled: !!selectedProject }
    );

  const [selectedProjectRole, setSelectedProjectRole] = useState();

  useEffect(() => {
    if (projectTeamMembers) {
      const userProjectRole = projectTeamMembers.filter(
        (ptm) => ptm.user_details.username === user.sub
      )[0]?.role;
      setSelectedProjectRole(projectRoles[userProjectRole]);
    } else {
      setSelectedProjectRole(undefined);
    }
    // eslint-disable-next-line
  }, [projectTeamMembers]);

  const filterRoutes = (routes) => {
    const authorizedRoutes = getAuthorizedRoutes(
      routes,
      permissions,
      selectedProjectRole
    );
    return filterVisibility(selectedProject, authorizedRoutes);
  };

  const userMayAccessRoute = (route) => {
    return isAuthorizedRoute(route, permissions, selectedProjectRole);
  };

  const userHasValidRole = (validRoles) => {
    return hasValidRole(validRoles, selectedProjectRole);
  };

  const userHasValidPermission = (validPermissions) => {
    return hasValidPermission(validPermissions, permissions);
  };

  const startAuthFlow = async () => {
    await loginWithRedirect(/*{
                appState: {targetUrl: path}
            }*/);
  };

  if (isAuthLoading) {
    return (
      <PublicLayout>
        <AppLoading />
      </PublicLayout>
    );
  }

  if (!isAuthenticated) {
    startAuthFlow();
    return (
      <PublicLayout>
        <AppLoading />
      </PublicLayout>
    );
  }

  return (
    <AuthorizationContext.Provider
      value={{
        loading,
        selectedProjectRole,
        filterRoutes,
        userMayAccessRoute,
        userHasValidRole,
        userHasValidPermission,
        permissions,
      }}
    >
      {children}
    </AuthorizationContext.Provider>
  );
};
